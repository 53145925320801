import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import tryCatchFinally from "../../../Function/TryCatchFinally";

const AnalysisResultDetail = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        BindedCheckBox,
        BindedSelect,
        layoutManager,
        getData,
        guid,
        useReducerSmart,
        toastPrint,
        getAppStore,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        CilentSideDataTable
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const defaultViewModel = {
        ...defaultParams,
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [heatingCoolingLimitViewModel, dispatchHeatingCoolingLimitViewModel, bindingHeatingCoolingLimitViewModel] = useReducerSmart(viewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const [errorLoading, setErrorLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [data, setData] = useState([]);
    const [reload, setReload] = useState("");
    //change model get url here if not using default model
    const getUrl = "api/AnalysisResult/Accessor/AnalysisDetail";
    const getHeatingCoolingLimitUrl = "api/DesignVariation/Accessor/GetHeatingCoolingLimit";
    const updateHeatingCoolingLimitUrl = "api/DesignVariation/Accessor/UpdateHeatingCoolingLimit";
    const columns = [
        { name: "name", text: "Zone", width: "50%" },
        { name: "energyConsumption", text: "Energy Consumption", width: "50%" }
      ];

    const loadError = async(requestModel) => {
        const response = await getData.processPost(true, "api/AnalysisResult/Accessor/GetErrors", requestModel);
        if(!response.state){
            toastPrint.printResponse(response);
        }
        if(response.state){
            setErrors(response.errorsAndWarnings);
        }
        setErrorLoading(false);
      }

    const handleApplyButtonClick = async (e)=>{
        await tryCatchFinally.asyncProcess(async()=>{
            setLoading({isLoading: true, text: "Updating heating cooling limit"});
            const responseUpdate = await getData.processPost(true, updateHeatingCoolingLimitUrl, heatingCoolingLimitViewModel);
            toastPrint.printResponse(responseUpdate);
            if(responseUpdate.state){
                const model = responseUpdate.viewModel;
                dispatchHeatingCoolingLimitViewModel(model);
            }
            setLoading({isLoading: false, text: ""});
        },setLoading);
    }

    useEffect(()=>{
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading data", appStoreDispatch, (newViewModel)=>{
            loadError({...newViewModel});
            setData(newViewModel.data);
            setReload(guid.newGuid());
        }, setInit);
    },[]);

    useEffect(()=>{
        if(init){
            loaModelFromApi.loadDefault(true, getHeatingCoolingLimitUrl, heatingCoolingLimitViewModel, dispatchHeatingCoolingLimitViewModel, setLoading, "Loading data", appStoreDispatch);
        }
    },[init]);

    useEffect(()=>{
        const newHeatingCoolingLimit = {...heatingCoolingLimitViewModel};
        if(heatingCoolingLimitViewModel.isNccClimateZone1Or2 === false){
            newHeatingCoolingLimit.hasOutHeatingCoolingLimitLivingArea = false;
            newHeatingCoolingLimit.hasOutHeatingCoolingLimitLivingAreaCeilingFan = false;
            dispatchHeatingCoolingLimitViewModel(newHeatingCoolingLimit);
        }
        if(heatingCoolingLimitViewModel.hasOutHeatingCoolingLimitLivingArea === false){
            newHeatingCoolingLimit.hasOutHeatingCoolingLimitLivingAreaCeilingFan = false;
            dispatchHeatingCoolingLimitViewModel(newHeatingCoolingLimit);
        }
    }, [heatingCoolingLimitViewModel.isNccClimateZone1Or2, heatingCoolingLimitViewModel.hasOutHeatingCoolingLimitLivingArea]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {debug && <p>{JSON.stringify(heatingCoolingLimitViewModel)}</p>}
            <div id="AnalysisResultsAccordionTitle">
                <div className="main-card mb-3 card">
                <div className="card-header">
                    <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#ProjectsCollapsibleDiv"
                    aria-expanded="true"
                    aria-controls="ProjectsCollapsibleDiv"
                    className="text-left m-0 p-0 btn btn-block"
                    >
                    {((viewModel.starRating === null || viewModel.starRating === "") &&  viewModel.starRating !== undefined) && <h5 style={{color:"red"}} className="m-0 p-0 card-title">No Analysis Summary Available, Requires Analysis</h5>}
                    {(viewModel.starRating !== null && viewModel.starRating !== undefined && viewModel.starRating !== "") && <h5 className="m-0 p-0 card-title">Analysis Summary</h5>}
                    </button>
                </div>
                <div id="AnalysisResultsCollapsibleDivSummary" className="collapse show">
                    <div className="card-body">           
                        {loading.isLoading && <AppLoading active><div>{loading.text}</div></AppLoading>} 
                        <table style={{width:"50%", marginLeft:"auto", marginRight:"auto", textAlign:"center"}}>
                            <tbody>
                                <tr>
                                    <th width="20%">
                                        <b>Heating/Limit</b>
                                    </th>
                                    <th width="20%">
                                        <b>Cooling/Limit</b>
                                    </th>
                                    <th width="20%">
                                        <b>Total Energy</b>
                                    </th>
                                    <th width="20%">
                                        <b>Units</b>
                                    </th>
                                    <th width="20%">
                                        <b>Star Rating</b>
                                    </th>
                                </tr>
                                <tr>
                                    <td>
                                        {viewModel.heating}{heatingCoolingLimitViewModel.heatingLimit === undefined ? "" : "/" + heatingCoolingLimitViewModel.heatingLimit}
                                    </td>
                                    <td>
                                        {viewModel.cooling}{heatingCoolingLimitViewModel.coolingLimit === undefined ? "" : "/" + heatingCoolingLimitViewModel.coolingLimit}
                                    </td>
                                    <td>
                                        {viewModel.totalEnergy}
                                    </td>
                                    <td>
                                        MJ/m² .annum
                                    </td>
                                    <td>
                                        {viewModel.starRating}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {/* <div className="text-center d-block p-3">
                            <a className="btn-pill btn-shadow btn-wide fsize-1 btn btn-primary btn-lg" href="https://hstar.com.au/QR?p=BrHnboVGP" target="_blank">
                                <span className="mr-2 opacity-7">
                                    <i className="metismenu-icon pe-7s-news-paper"></i>
                                </span>
                                <span className="mr-1">View certificate</span>
                            </a>
                        </div> */}
                    </div>
                </div>
                </div>
            </div>
            <div id="AnalysisResultsAccordionAnalysisHeatingCoolingLimit">
                <div className="main-card mb-3 card">
                <div className="card-header">
                    <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#ProjectsCollapsibleDiv"
                    aria-expanded="true"
                    aria-controls="ProjectsCollapsibleDiv"
                    className="text-left m-0 p-0 btn btn-block"
                    >
                    <h5 className="m-0 p-0 card-title">Heating Cooling Limit</h5>
                    </button>
                </div>
                <div id="AnalysisResultsCollapsibleDivError" className="collapse show">
                    <div className="card-body">           
                        <div className="form-row row">
                            <div className="col-12">
                                <div className="position-relative form-group">
                                <label htmlFor="LoadLimitFloorTypeId">Floor Type *</label>
                                <BindedSelect
                                    style={{marginBottom: "15px"}}
                                    className="form-control"
                                    id="LoadLimitFloorTypeId"
                                    name="LoadLimitFloorTypeId"
                                    binding={[heatingCoolingLimitViewModel, "loadLimitFloorTypeId", bindingHeatingCoolingLimitViewModel]}
                                    options={"loadLimitFloorTypes"}
                                ></BindedSelect>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="form-check">
                                    <BindedCheckBox
                                        id="IsNccClimateZone1Or2" 
                                        name="IsNccClimateZone1Or2"
                                        binding={[heatingCoolingLimitViewModel, "isNccClimateZone1Or2", bindingHeatingCoolingLimitViewModel]}
                                    ></BindedCheckBox>
                                    <label htmlFor="IsNccClimateZone1Or2" className="form-check-label">NCC Climate Zone 1 Or 2</label>
                                </div>
                            </div>
                            {heatingCoolingLimitViewModel.isNccClimateZone1Or2 === true &&
                               <div className="col-12">
                                <div className="form-check">
                                    <BindedCheckBox
                                        id="HasOutHeatingCoolingLimitLivingArea" 
                                        name="HasOutHeatingCoolingLimitLivingArea"
                                        binding={[heatingCoolingLimitViewModel, "hasOutHeatingCoolingLimitLivingArea", bindingHeatingCoolingLimitViewModel]}
                                    ></BindedCheckBox>
                                    <label htmlFor="HasOutHeatingCoolingLimitLivingArea" className="form-check-label">Out Door Leaving Area</label>
                                </div>
                            </div>
                            }
                          {heatingCoolingLimitViewModel.hasOutHeatingCoolingLimitLivingArea === true &&
                            <div className="col-12">
                                <div className="form-check">
                                    <BindedCheckBox
                                        id="HasOutHeatingCoolingLimitLivingAreaCeilingFan" 
                                        name="HasOutHeatingCoolingLimitLivingAreaCeilingFan"
                                        binding={[heatingCoolingLimitViewModel, "hasOutHeatingCoolingLimitLivingAreaCeilingFan", bindingHeatingCoolingLimitViewModel]}
                                    ></BindedCheckBox>
                                    <label htmlFor="HasOutHeatingCoolingLimitLivingAreaCeilingFan" className="form-check-label">Out Door Leaving Area Ceiling Fan</label>
                                </div>
                            </div>
                          }  
                            <div className="ml-auto">
                            <a
                                className="btn btn-primary btn-round"
                                id="ApplyButton"
                                onClick={(e) => {
                                    handleApplyButtonClick(e);
                                }}
                            >Apply</a>
                            </div>               
                        </div>
                    </div>
                </div>
                </div>
            </div>
            <div id="AnalysisResultsAccordionAnalysisDetail">
                <div className="main-card mb-3 card">
                <div className="card-header">
                    <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#ProjectsCollapsibleDiv"
                    aria-expanded="true"
                    aria-controls="ProjectsCollapsibleDiv"
                    className="text-left m-0 p-0 btn btn-block"
                    >
                    <h5 className="m-0 p-0 card-title">Analysis detail</h5>
                    </button>
                </div>
                <div id="AnalysisResultsCollapsibleDivError" className="collapse show">
                    <div className="card-body">           
                       <CilentSideDataTable
                            id = "AnalysisResultDetail_f2ba7434-cdb7-4dc6-823a-8bee03380075"
                            data = {data}
                            setData = {setData}
                            columns = {columns}   
                            reload = {reload}                 
                            disableSorting
                       ></CilentSideDataTable>
                    </div>
                </div>
                </div>
            </div>
            <div id="AnalysisResultsAccordionError">
                <div className="main-card mb-3 card">
                <div className="card-header">
                    <button
                    type="button"
                    data-toggle="collapse"
                    data-target="#ProjectsCollapsibleDiv"
                    aria-expanded="true"
                    aria-controls="ProjectsCollapsibleDiv"
                    className="text-left m-0 p-0 btn btn-block"
                    >
                    <h5 className="m-0 p-0 card-title">Error and warning</h5>
                    </button>
                </div>
                <div id="ProjectsCollapsibleDiv" className="collapse show">
                    <div className="card-body">           
                        {errorLoading && <p>Loading error and warning</p>}
                        <ul className="list-group">
                            {errors.map((e)=>{
                                return(<li className="list-group-item" key={guid.newGuid()}>{e.content} Type: {e.type === 1 ? "Error" : "Warning"}</li>);
                            })}
                        </ul>
                    </div>
                </div>
                </div>
            </div>
        </Fragment>
    );
}
export default connectAppStore(AnalysisResultDetail);