import { Fragment, useEffect, useState } from "react";
import { connectAppStore } from "../../../../Store/AppStore";
import PageDependencies from "../../../../Feature/PageDependencies";
import objectPropertyValueGetSet from "../../../../Function/ObjectPropertyValueGetSet";

const MultipleWindowsDetail = (props) =>{
    const { 
        stringCoding,
        layoutManager,
        guid,
        useReducerSmart,
        useParams,
        BaseForm,
        BindedInput,
        BindedTriStateCheckBox,
        BindedSelect,
        getAppStore,
        pageProfile,
        loaModelFromApi,
        dataExtension,
        useEffectWithPrevious,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const emptyGuid = guid.emptyGuid();
    const projectId = useParams().projectId;
    const designVariationId = useParams().designVariationId;
    const developmentId = useParams().developmentId;
    const zoneId = useParams().zoneId;
    const redirectUrl = stringCoding.decode(useParams().redirectUrlHex);
    if(zoneId === undefined) zoneId = emptyGuid;
    const selectedElementIds = stringCoding.decodeObject(useParams().jsonParamHex);
    const defaultViewModel = {
        developmentId: developmentId,
        projectId: projectId, 
        designVariationId: designVariationId,
        designVariationVersionName:"V001",
        zoneId: zoneId,
        selectedItemIds: selectedElementIds
      };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const postUrl = "api/Element/Accessor/UpdateMultipleWindows";
    const getUrl = "api/Element/Accessor/GetMultipleWindows";

    useEffect(()=>{   
        layoutManager.Layout_Headter_Footer_SideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading windows", appStoreDispatch);
    },[]);

    useEffectWithPrevious((oldValue)=>{
        if(oldValue === undefined || oldValue[0] === undefined) return;
        if(viewModel.fixedShadingId != emptyGuid){
            const selected = dataExtension.getSelectOptionByValue(viewModel.fixedShadings, viewModel.fixedShadingId);
            const newModel = {... viewModel};
            if(newModel.horizontalShadingElement === undefined || newModel.horizontalShadingElement === null){
                newModel.horizontalShadingElement = {};
            }
            newModel.horizontalShadingElement.eaveWidth = 0;
            newModel.horizontalShadingElement.eaveLength = 0;
            newModel.horizontalShadingElement.eaveVerticalOffset = 0;
            newModel.horizontalShadingElement.eaveHorizontalOffset = 0;
            newModel.horizontalShadingElement.eaveProjectionTypeId = 2;
            newModel.horizontalShadingElement.shadingInputMethodTypeId = 1;
            newModel.horizontalShadingElement.otherFixedShadingWidth = 0
            newModel.horizontalShadingElement.otherFixedShadingLength = 0;
            newModel.horizontalShadingElement.otherFixedShadingVerticalOffset = 0;
            newModel.horizontalShadingElement.otherFixedShadingHorizontalOffset = 0;
            newModel.horizontalShadingElement.otherFixedShadingProjectionTypeId = 2;
            newModel.horizontalShadingElement.eaveProjectionTypes = [{text:"Length", value: 1}, {text:"Width", value: 2}];
            newModel.horizontalShadingElement.otherFixedShadingProjectionTypes = [{text:"Length", value: 1}, {text:"Width", value: 2}];
            //eave
            if(selected.eaveWidth !== undefined && selected.eaveWidth != null && parseInt(selected.eaveWidth) > 0){
                newModel.horizontalShadingElement.eaveWidth = selected.eaveWidth;
            }
            if(selected.eaveLength !== undefined && selected.eaveLength != null){
                if (selected.shadingInputMethodTypeId == 2) {
                    newModel.horizontalShadingElement.eaveLength = viewModel.length + selected.eaveWidth * 2;
                }
                else{
                    newModel.horizontalShadingElement.eaveLength = selected.eaveLength;
                }
            }
            if(selected.eaveVerticalOffset !== undefined && selected.eaveVerticalOffset != null && parseInt(selected.eaveVerticalOffset) > -999999999999){
                newModel.horizontalShadingElement.eaveVerticalOffset = selected.eaveVerticalOffset;
            }
            if(selected.eaveHorizontalOffset !== undefined && selected.eaveHorizontalOffset != null && parseInt(selected.eaveHorizontalOffset) > -999999999999){
                newModel.horizontalShadingElement.eaveHorizontalOffset = selected.eaveHorizontalOffset;
            }
            if(selected.eaveProjectionTypeId !== undefined && selected.eaveProjectionTypeId != null && parseInt(selected.eaveProjectionTypeId) > -999999999){
                newModel.horizontalShadingElement.eaveProjectionTypeId = selected.eaveProjectionTypeId;
            }
            if(selected.shadingInputMethodTypeId !== undefined && selected.shadingInputMethodTypeId != null && parseInt(selected.shadingInputMethodTypeId) > -999999999){
                newModel.horizontalShadingElement.shadingInputMethodTypeId = selected.shadingInputMethodTypeId;
            }
            //pergola
            if(selected.otherFixedShadingWidth !== undefined && selected.otherFixedShadingWidth != null && parseInt(selected.otherFixedShadingWidth) > 0){
                newModel.horizontalShadingElement.otherFixedShadingWidth = selected.otherFixedShadingWidth;
            }
            if(selected.otherFixedShadingLength !== undefined && selected.otherFixedShadingLength != null && parseInt(selected.otherFixedShadingLength) > 0){
                newModel.horizontalShadingElement.otherFixedShadingLength = selected.otherFixedShadingLength;
            }
            if(selected.otherFixedShadingVerticalOffset !== undefined && selected.otherFixedShadingVerticalOffset != null && parseInt(selected.otherFixedShadingVerticalOffset) > -999999999999){
                newModel.horizontalShadingElement.otherFixedShadingVerticalOffset = selected.otherFixedShadingVerticalOffset;
            }
            if(selected.otherFixedShadingHorizontalOffset !== undefined && selected.otherFixedShadingHorizontalOffset != null && parseInt(selected.otherFixedShadingHorizontalOffset) > -999999999999){
                newModel.horizontalShadingElement.otherFixedShadingHorizontalOffset = selected.otherFixedShadingHorizontalOffset;
            }
            if(selected.otherFixedShadingProjectionTypeId !== undefined && selected.otherFixedShadingProjectionTypeId != null && parseInt(selected.otherFixedShadingProjectionTypeId) > -999999999){
                newModel.horizontalShadingElement.otherFixedShadingProjectionTypeId = selected.otherFixedShadingProjectionTypeId;
            }
            dispatchViewModel(newModel);
        }
    },[viewModel.fixedShadingId]);

    const getBlockingFactors = (blockingFactor) => {
        const value = objectPropertyValueGetSet.getPropertyValue(viewModel, blockingFactor);
        return value;
    }

    return(<Fragment>
        {debug && <p>{JSON.stringify(viewModel)}</p>}
        <BaseForm
           id="FloorCeilingFrom"
           icon="pe-7s-safe"
           loading={loading}
           setLoading={setLoading}
           submitButton= {{text:"Save and close", postUrl: postUrl, redirectUrl: redirectUrl }}
           cancelButton={{ redirectUrl: redirectUrl }}
           data={viewModel}
           title = "Windows detail"
           subTitle={pageProfile.getPageSubTitle(appStoreState)}
        >
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowName">Name *</label>
                    <BindedInput
                        className="form-control"
                        id="WindowName"
                        name="WindowName"
                        type="text"
                        binding={[viewModel, "name", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                        <label htmlFor="WindowConstructionId">
                            Construction *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowConstructionId"
                            name="WindowConstructionId"
                            options="constructions"
                            binding={[
                            viewModel,
                            "constructionId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                    </div>
                </div>
            </div>
            <div className="form-row row">
            <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="WindowTypeId">
                            Type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowTypeId"
                            name="WindowTypeId"
                            options="windowTypes"
                            binding={[
                            viewModel,
                            "windowTypeId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                </div>
            </div>
            <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="WindowIndoorCoveringTypeId">
                        Indoor covering type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowIndoorCoveringTypeId"
                            name="WindowIndoorCoveringTypeId"
                            options="indoorCoveringTypes"
                            binding={[
                            viewModel,
                            "indoorCoveringTypeId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                </div>
            </div>
            <div className="col-4">
                <div className="position-relative form-group">
                    <label htmlFor="WindowOutdoorCoveringTypeId">
                        Outdoor covering Type *
                        </label>
                        <BindedSelect
                            className="form-control"
                            id="WindowOutdoorCoveringTypeId"
                            name="WindowOutdoorCoveringTypeId"
                            options="outdoorCoveringTypes"
                            binding={[
                            viewModel,
                            "outdoorCoveringTypeId",
                            bindingViewModel,
                            ]}
                        ></BindedSelect>
                </div>
            </div>                
            </div>
            <div className="form-row row">
                <div className="col-12">
                    <div className="position-relative form-group">
                    <label htmlFor="ExternalWallFixedShadingId">
                        Fixed shade
                    </label>
                    <BindedSelect
                        className="form-control"
                        id="ExternalWallFixedShadingId"
                        name="ExternalWallFixedShadingId"
                        options="fixedShadings"
                        binding={[
                        viewModel,
                        "fixedShadingId",
                        bindingViewModel,
                        ]}
                    ></BindedSelect>
                    </div>
                </div>
            </div>
            {viewModel.fixedShadingId !== null && viewModel.fixedShadingId !== emptyGuid && viewModel.simulationType === "New Home" && <Fragment>
                <div className="form-row row">
                    <div className="col-12">
                        <h6>Eave</h6>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveLength">Length (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                name="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveLength", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveWidth">Width (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                name="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveWidth", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId">Projection</label>
                            <BindedSelect
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                name="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                options="horizontalShadingElement.eaveProjectionTypes"
                                binding={[viewModel, "horizontalShadingElement.eaveProjectionTypeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset">Vertical offset (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveVerticalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset">Horizontal offset (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveHorizontalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-12">
                        <h6>Other fixed shading</h6>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength">Length (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingLength", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth">Width (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingWidth", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId">Projection</label>
                            <BindedSelect
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                options="horizontalShadingElement.eaveProjectionTypes"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingProjectionTypeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset">Vertical offset (mm)</label>
                            <BindedInput
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingVerticalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset">Horizontal offset (mm)</label>
                            <BindedInput
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingHorizontalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-12">
                        <label>Blocking factor (opaque = 100%)</label>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage0")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage1")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage2")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage3")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage4">May</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage4")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage5")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage6")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage7")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage8")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage9")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage10")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage11")}</label>
                        </div>
                    </div>
                </div>
                <div className="form-row row"></div>
            </Fragment>}
            {viewModel.fixedShadingId !== null && viewModel.fixedShadingId !== emptyGuid && viewModel.simulationType !== "New Home" && <Fragment>
                <div className="form-row row">
                    <div className="col-12">
                        <h6>Eave</h6>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveLength">Eave Length (mm)</label>
                            {viewModel.horizontalShadingElement !== undefined && viewModel.horizontalShadingElement.shadingInputMethodTypeId == 2 && viewModel.length === null &&
                            <label className="form-control" style={{ backgroundColor: "#e9ecef" }}> (Multiple Values)</label>}
                            {viewModel.horizontalShadingElement !== undefined && (viewModel.horizontalShadingElement.shadingInputMethodTypeId != 2 || viewModel.length !== null) &&
                            <BindedInput
                                disabled
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                name="ExternalWallFixedShading_HorizontalShadingEaveLength"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveLength", bindingViewModel]}>
                            </BindedInput>}
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveWidth">Eave Width (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                name="ExternalWallFixedShading_HorizontalShadingEaveWidth"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveWidth", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId">Projection</label>
                            <BindedSelect
                                disabled
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                name="ExternalWallFixedShading_HorizontalShadingEaveProjectionTypeId"
                                options="horizontalShadingElement.eaveProjectionTypes"
                                binding={[viewModel, "horizontalShadingElement.eaveProjectionTypeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset">Eave Vertical offset (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingEaveVerticalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveVerticalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset">Eave Horizontal offset (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingEaveHorizontalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.eaveHorizontalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-12">
                        <h6>Other fixed shading</h6>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength">Overhang Length (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingLength"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingLength", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth">Overhang Width (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingWidth"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingWidth", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId">Projection</label>
                            <BindedSelect
                                disabled
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingProjectionTypeId"
                                options="horizontalShadingElement.eaveProjectionTypes"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingProjectionTypeId", bindingViewModel]}
                            ></BindedSelect>
                        </div>
                    </div>
                </div>
                <div className="form-row row">
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset">Overhang Vertical offset (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingVerticalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingVerticalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                    <div className="col-4">
                        <div className="position-relative form-group">
                            <label htmlFor="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset">Overhang Horizontal offset (mm)</label>
                            <BindedInput
                                disabled
                                className="form-control valid"
                                id="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                name="ExternalWallFixedShading_HorizontalShadingOtherFixedShadingHorizontalOffset"
                                type="number"
                                binding={[viewModel, "horizontalShadingElement.otherFixedShadingHorizontalOffset", bindingViewModel]}>
                            </BindedInput>
                        </div>
                    </div>
                </div>
                {/* <div className="form-row row">
                    <div className="col-12">
                        <label>Blocking factor (opaque = 100%)</label>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage0">Jan</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage0")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage1">Feb</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage1")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage2">Mar</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage2")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage3">Apr</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage3")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage4">May</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage4")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage5">Jun</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage5")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage6">Jul</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage6")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage7">Aug</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage7")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage8">Sep</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage8")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage9">Oct</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage9")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage10">Nov</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage10")}</label>
                        </div>
                    </div>
                    <div className="col-1">
                        <div className="position-relative form-group">
                            <label htmlFor="ShadingBlockingFactorPercentage11">Dec</label>
                            <label className="form-control" style={{ border: "none", backgroundColor: "white" }}> {getBlockingFactors("horizontalShadingElement.blockingFactorPercentage11")}</label>
                        </div>
                    </div>
                </div> */}
                <div className="form-row row"></div>
            </Fragment>}

            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowWidth">Width (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="WindowWidth"
                        name="WindowWidth"
                        type="number"
                        min="0"
                        binding={[viewModel, "length", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowHeight">Height (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="WindowHeight"
                        name="WindowHeight"
                        type="number"
                        min="0"
                        binding={[viewModel, "height", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                {/* <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="ExternalWallGrossArea">
                        Area (m<sup>2</sup>) *
                    </label>
                    <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateArea(viewModel.length, viewModel.height)}</label>
                    </div>
                </div> */}
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowHeadHeight">Head height (mm) *</label>
                    <BindedInput
                        className="form-control"
                        id="WindowHeadHeight"
                        name="WindowHeadHeight"
                        type="number"
                        min="0"
                        binding={[viewModel, "headHeight", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                {/* <div className="col-4">
                    <div className="position-relative form-group">
                    <label>
                        Height from floor (mm)
                    </label>
                    <label className="form-control" style={{ border: "none", backgroundColor: "white" }}>{calculateHeightFromFloor()}</label>
                    </div>
                </div> */}
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowHorizontalOffset">
                        Horizontal offset (mm) *
                    </label>
                    <BindedInput
                        className="form-control"
                        min="0"
                        id="WindowHorizontalOffset"
                        name="WindowHorizontalOffset"
                        type="number"
                        binding={[viewModel, "horizontalOffset", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowOpenablePercent">
                        Openable (%) *
                    </label>
                    <BindedInput                                 
                        className="form-control"
                        min="0"
                        max="100"
                        id="WindowOpenablePercent"
                        name="WindowOpenablePercent"
                        type="number"
                        binding={[viewModel, "openablePercent", bindingViewModel]}
                    ></BindedInput>
                    </div>
                </div>     
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        disabled={viewModel.dwellingType === "New"}
                        id="WindowHasInsectScreens"
                        name="WindowHasInsectScreens"
                        className="custom-control-input"
                        binding={[viewModel, "hasInsectScreens", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                    style={{ marginLeft: "5px" }}
                    htmlFor="WindowHasInsectScreens"
                    className="custom-control-label"
                    >
                    Insect screens
                    </label>
                </div>
            </div>
            <div className="form-row row">
                <div className="custom-checkbox custom-control">
                    <BindedTriStateCheckBox
                        id="WindowIsWeatherstripped"
                        name="WindowIsWeatherstripped"
                        className="custom-control-input"
                        binding={[viewModel, "isWeatherstripped", bindingViewModel]}
                    ></BindedTriStateCheckBox>
                    <label
                    style={{ marginLeft: "5px" }}
                    htmlFor="WindowIsWeatherstripped"
                    className="custom-control-label"
                    >
                    Weatherstripped
                    </label>
                </div>
            </div>
            <div className="form-row row">
                <div className="col-4">
                    <div className="position-relative form-group">
                    <label htmlFor="WindowGapSizeTypeId">
                        Gap size
                    </label>
                    <BindedSelect
                        disabled={viewModel.isWeatherstripped===true||viewModel.isWeatherstripped===null}
                        className="form-control"
                        id="WindowGapSizeTypeId"
                        name="WindowGapSizeTypeId"
                        options="gapSizeTypes"
                        binding={[
                        viewModel,
                        "gapSizeTypeId",
                        bindingViewModel,
                        ]}
                    ></BindedSelect>
                    </div>
                </div>
            </div>
            {viewModel.simulationType === "Existing Home" &&
                                    <>
                                        <div className="form-row row">
                                            <div className="col-6">
                                                <div className="custom-checkbox custom-control">
                                                    <BindedTriStateCheckBox
                                                        id="IsExistingIndoorCoveringPresent"
                                                        name="IsExistingIndoorCoveringPresent"
                                                        className="custom-control-input"
                                                        binding={[viewModel, "isExistingIndoorCoveringPresent", bindingViewModel]}
                                                    ></BindedTriStateCheckBox>
                                                    <label                      
                                                    style={{ marginLeft: "5px" }}
                                                    htmlFor="IsExistingIndoorCoveringPresent"
                                                    className="custom-control-label"
                                                    >
                                                    Indoor Window Covering Characteristics
                                                    </label>
                                                </div>
                                            </div>
                                        </div>                                     
                                                                                             
                                    </>
                                }   

                                         {viewModel.isExistingIndoorCoveringPresent === true &&
                                            <>
                                                <div className="form-row row">
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                            <label htmlFor="ExistingWindowStreetSideAppearanceTypeId">Outside appearance (colour)</label>
                                                            <BindedSelect
                                                                className="form-control"
                                                                id="ExistingWindowStreetSideAppearanceTypeId"
                                                                name="ExistingWindowStreetSideAppearanceTypeId"
                                                                options="existingWindowStreetSideAppearanceTypes"
                                                                binding={[viewModel, "existingWindowStreetSideAppearanceTypeId", bindingViewModel]}
                                                            ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-row row">
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                            <label htmlFor="ExistingWindowLightPassingThroughTypeId">Light transmittance</label>
                                                            <BindedSelect
                                                                className="form-control"
                                                                id="ExistingWindowLightPassingThroughTypeId"
                                                                name="ExistingWindowLightPassingThroughTypeId"
                                                                options="existingWindowLightPassingThroughTypes"
                                                                binding={[viewModel, "existingWindowLightPassingThroughTypeId", bindingViewModel]}
                                                            ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>
                                                 <div className="form-row row">                                                                                                               
                                                    <div className="col-6">
                                                        <div className="position-relative form-group">
                                                        <label htmlFor="ExistingWindowInsulationAirflowTypeId">Insulation value + Window covering fit</label>
                                                        <BindedSelect
                                                            className="form-control"
                                                            id="ExistingWindowInsulationAirflowTypeId"
                                                            name="ExistingWindowInsulationAirflowTypeId"
                                                            options="existingWindowInsulationAirflowTypes"
                                                            binding={[viewModel, "existingWindowInsulationAirflowTypeId", bindingViewModel]}
                                                        ></BindedSelect>
                                                        </div>
                                                    </div>
                                                </div>   
                                            </>                              
                                        }      
        </BaseForm>
    </Fragment>);
}
export default connectAppStore(MultipleWindowsDetail);