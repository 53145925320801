import { useState, useRef, useEffect } from 'react';

function useStateWithCallbackAe(initialValue) {
    const [state, setState] = useState(initialValue);
    const callbackRef = useRef(null);
  
    const setStateWithCallback = (newState, callback) => {
      callbackRef.current = callback;
      setState(newState);
    };
  
    // After the state is updated, call the callback if it's set
    useEffect(() => {
      if (callbackRef.current) {
        callbackRef.current(state);
        callbackRef.current = null; // Reset the callback
      }
    }, [state]);
  
    return [state, setStateWithCallback];
  }

  export default useStateWithCallbackAe;