import { Fragment } from "react";
import BaseFormNoHeader from "./BaseFormNoHeader";

const BaseForm = (props) => {
  return (
    <Fragment>
      <div className="app-main__inner">
        <div className="app-page-title">
          <div className="page-title-wrapper">
            <div className="page-title-heading">
              <div className="page-title-icon">
                <i
                  className={
                    "metismenu-icon " +
                    props.icon +
                    " icon-gradient bg-tempting-azure"
                  }
                ></i>
              </div>
              <div>
                {props.title}
                <div className="page-title-subheading">{props.subTitle}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="main-card mb-3 card" >
        <BaseFormNoHeader
            id={props.id}
            init = {props.init}
            submitButton={props.submitButton}
            submitButtons={props.submitButtons}
            submitButtonDisabled={props.submitButtonDisabled}
            submitButtonAndStay={props.submitButtonAndStay}
            submitButtonAndStayDisabled={props.submitButtonAndStayDisabled}
            cancelButton={props.cancelButton}
            validate={props.validate}
            loading={props.loading}
            setReload = {props.setReload}
            setLoading={props.setLoading}
            data={props.data}
            submitCallBack = {props.submitCallBack}
          >
            {props.children}
          </BaseFormNoHeader>
        </div>
      </div>
    </Fragment>
  );
};
export default BaseForm;
