import React, { Fragment, useEffect } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import { Calendar } from 'primereact/calendar';
import PageDependencies from './PageDependencies';

const BindedInputCalendar = (props) => {
    const {useState, guid} = PageDependencies();
    const newProps = {};
    const binding = props.binding;
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const [value, setValue] = useState(bindingValue);
    const [event, setEvent] = useState();
    const validate = props.validate;
    let inputStyle = props.inputStyle;
    if (inputStyle !== undefined){
        inputStyle.padding = 6;
    }else{
        inputStyle = {padding: 6};
    }
    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')) {
            newProps[prop] = props[prop];
        }
    }
    let label = props.label;
    let inputId = props.inputId;
    if(inputId === undefined){
        inputId = guid.newGuid();
    }
    if(label === undefined){
        label = "";
    }
    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }  
    }, []);

    useEffect(()=>{
        if(bindingValue != value){
            setValue(bindingValue);
        }
    },[bindingValue]);

    useEffect(()=>{
            const timer = setTimeout(()=>{
                if(bindingValue != value){
                    const bindingObjectNew = { };
                    bindingObjectNew[bindingProperty] = value;          
                    bindingMethod(bindingObjectNew, event);
                    if (validate !== undefined) {
                        const validateObject = { ...bindingObject };
                        validateObject[bindingProperty] = value;
                        validate.checkValidateResult(validateObject);
                    }
                }
            }, 300);
            return () => {
                clearTimeout(timer);
              };
        },[value]);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return (
        <Fragment>
            <div className="flex flex-wrap gap-3 p-fluid">
                <div className="flex-auto">
                    <label htmlFor={inputId} className="block mb-2">{label}</label>    
                    <Calendar inputRef={props.componentRef} inputId={inputId} inputStyle={inputStyle} value={value} {...newProps} onChange={(e) => {
                        let value = e.target.value   
                        setEvent(e, (newEvent)=>{
                            setValue(value);
                        })
                    }}></Calendar>
                {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
              </div>
            </div>         
        </Fragment>
    );
};
export default BindedInputCalendar;