import { Fragment } from "react";
import PageDependencies from "../../../Feature/PageDependencies";

const CreditDataTable = (props)=>{
    const { 
        useState,
        dataExtension,
        ServerSideDataTable,
        ModalDatatableItem,
    } = PageDependencies();
    const debug = props.debug === undefined ? false : props.debug;
    const data = props.data;
    const setData = props.setData;
    const tableId = "Credit_12e1212f-6b03-4a7a-a396-1099c0f59d66";
    const tableReload = props.tableReload;
    const extraPostModel = props.extraPostModel;
    const url = props.url;
    const [selectedIds, setSelectedIds] = useState([]);
    const columns =[
      { name: "purchaseDate", text: "Purchase Date", width: "20%" },
      { name: "creditType", text: "Credit Type", width: "15%" },
      { name: "unitPrice", text: "Unit Price", width: "15%" },
      { name: "numberOfCreditsPurchased", text: "Number Of Credits Purchased", width: "20%" },
      //{ name: "numberOfCreditsRemaining", text: "Number Of Credits Remaining", width: "17%" },
      { name: "expiryDate", text: "ExpiryDate", width: "20%" }
    ];

    const [modal, setModal] = useState({
      isOpen: false,
      title: "",
      message: "",
      comfirmButtonStyle: "",
      comfirmButtonText: "Comfirm",
      handleComfirm: () => {},
    });

    const contextMenu = {
      items: {
        purchase: {
          name: "Purchase credits",
          icon: "",
          defaultAction: true,
          url: "/UserAccessor/Credits/Purchase",
          isNavOption: true
        },
        refund: {
          name: "Refund",
          icon: "",
          defaultAction: true,
          url: "/UserAccessor/Credits/Refund/" + dataExtension.getSelectedRowDataIds(data)[0],
          isNavOption: true,
          disabled: function () {
            return dataExtension.getSelectedRowDataIds(data).length !== 1;}
        },
      },callBack: (
        contextMenuCallBackKey,
        contextMenuCallBackElement,
        contextMenuCallBackTableRoadData,
        localtion
      ) => {
      },
    };

    return(<Fragment>
      {debug && <p>{JSON.stringify(dataExtension.getSelectedRowDataIds(data))}</p>}
      <ModalDatatableItem
        modal={modal}
        setModal={setModal}
      ></ModalDatatableItem>
      <ServerSideDataTable
        allowSelect
        debug ={debug}
        id={tableId}
        location="UserCredits"
        data={data}
        setData={setData}
        reload={tableReload}
        columns={columns}
        dataSource={{ isAuth: true, url: url }}
        contextMenu={contextMenu}
        extraPostModel={extraPostModel}
        setSelectedItemIds = {setSelectedIds}
        ></ServerSideDataTable>
    </Fragment>);
}
export default CreditDataTable;