import React, { Fragment, useEffect } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import PageDependencies from './PageDependencies';

const BindedInput = (props) => {
    const {useState} = PageDependencies();
    const newProps = {};
    const binding = props.binding;
    let type = props.type;
    if(type === undefined){
        type = "";
    }else{
        type = type.toLowerCase();
    }
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    let bindingDefaultValue = binding[3];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const [value, setValue] = useState(bindingValue);
    const [event, setEvent] = useState();
    const validate = props.validate;

    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')) {
            newProps[prop] = props[prop];
        }
    }

    useEffect(() => {
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }    
    }, []);

    useEffect(()=>{
        if(bindingValue != value){
            setValue(bindingValue);
        }
    },[bindingValue]);

    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(bindingValue != value){
                const bindingObjectNew = { };
                bindingObjectNew[bindingProperty] = value;         
                bindingMethod(bindingObjectNew, event, undefined, undefined, bindingDefaultValue);
                if (validate !== undefined) {
                    const validateObject = { ...bindingObject };
                    validateObject[bindingProperty] = value;
                    validate.checkValidateResult(validateObject);
                }
            }
        }, 300);
        return () => {
            clearTimeout(timer);
          };
    },[value]);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return (
        <Fragment>
            {type === "file" && <input {...newProps} ref={props.componentRef} onChange={(e) => {
                let value = e.target.value
                setEvent(e, (newEvent) => {
                    setValue(value);
                })
            }} />}
            {type !== "file" && <input {...newProps} ref={props.componentRef} value={value} onChange={(e) => {
                let value = e.target.value
                setEvent(e, (newEvent) => {
                    setValue(value);
                })
            }} />}
            {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
        </Fragment>
    );
};
export default BindedInput;