import { connectAppStore } from "../../Store/AppStore";
import PageDependencies from "../../Feature/PageDependencies";
const AddCredits = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        tryCatchFinally,
        toastPrint,
        layoutManager,
        getData,
        useReducerSmart,
        BindedInput,
        getAppStore,
        useNavigate,
        pageProfile,
        AppLoading,
        loaModelFromApi,
        FormValidate,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const nav = useNavigate();
    const [init, setInit] = useState(false);
    const { id } = defaultParams;
    const defaultViewModel = {
        userId: id
    };
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/CreditAdmin/AddCredits";
    const validateMethods = {
        qualitySimulation:(value, newModel)=>{ 
            if(newModel.qualityCertificate > 0) return "";
            return FormValidate.validateNumberGreaterThanOrEqual0(value, "Simulation number");
        },
        qualityCertificate:(value, newModel)=>{ 
            if(newModel.qualitySimulation > 0) return "";
            return FormValidate.validateNumberGreaterThanOrEqual0(value, "Certificate number");
        },
    };
    const formValidate = new FormValidate(useState(validateMethods), useState(), useState(), useState(), false);

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loaModelFromApi.loadDefault(true, getUrl, viewModel, dispatchViewModel, setLoading, "Loading Add Credits", appStoreDispatch, undefined, setInit);
    },[]);

    const submit = ()=>{
        formValidate.setValidateVisibility(true);
        const validateResult = formValidate.checkValidateResult(viewModel);
        if(!validateResult) return;
        const postUrl = "api/CreditAdmin/SaveCredits";
        setLoading({ isLoading: true, text: "Submitting" });
        tryCatchFinally.syncProcess(async()=>{
            const response = await getData.processPost(true, postUrl, viewModel);
            toastPrint.printResponse(response);
            const urlParams = response.userId;
            nav("/Admin/Credits/" + urlParams);
        }, setLoading);
    }

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Add Credits
                            </div>
                        </div>
                    </div>
                </div>
                {init === true &&
                     <div className="main-card mb-3 col-3 card">
                     <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">
                         <div className="card-body">
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label htmlFor="QualitySimulation">Simulation add </label>
                                         <BindedInput
                                             className="form-control"
                                             id="QualitySimulation"
                                             name="QualitySimulation"
                                             type="number"
                                             min="0"
                                             step="1"
                                             validate = {formValidate}
                                             binding={[viewModel, "qualitySimulation", bindingViewModel]}
                                         ></BindedInput>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label htmlFor="QualityCertificate">Certificate add </label>
                                         <BindedInput
                                             className="form-control"
                                             id="QualityCertificate"
                                             name="QualityCertificate"
                                             type="number"
                                             min="0"
                                             step="1"
                                             validate = {formValidate}
                                             binding={[viewModel, "qualityCertificate", bindingViewModel]}
                                         ></BindedInput>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <label htmlFor="QualityCertificate">Reason </label>
                                         <BindedInput
                                             className="form-control"
                                             id="QualityCertificate"
                                             name="QualityCertificate"
                                             type="text"
                                             binding={[viewModel, "reason", bindingViewModel]}
                                         ></BindedInput>
                                     </div>
                                 </div>
                             </div>
                             <div className="form-row row">
                                 <div className="col-12">
                                     <div className="position-relative form-group">
                                         <button type="button" style={{marginTop:"10px"}} className="btn btn-primary" onClick={submit}>Checkout</button>
                                     </div>
                                 </div>
                             </div>
                         </div>
                     </div>
                 </div>
                
                }
           
            </div>
        </Fragment>
    );
}
export default  connectAppStore(AddCredits);
