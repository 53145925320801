import { connectAppStore } from "../../../Store/AppStore";
import PageDependencies from "../../../Feature/PageDependencies";
import CreditDataTable from "./CreditDataTable";
const UserCredits = (props) => {
    const { 
        Fragment,
        useEffect,
        useState,
        useParams,
        layoutManager,
        useReducerSmart,
        getAppStore,
        pageProfile,
        AppLoading,
        getData,
        toastPrint,
        tryCatchFinally,
    } = PageDependencies();
    const debug = layoutManager.Layout_GetDebugModel(props);
    const defaultParams = pageProfile.getDefaultParams(useParams());
    const [init, setInit] = useState(false);
    const { } = defaultParams;
    const defaultViewModel = {
        ...defaultParams,
    };
    const [extraPostModel, setExtraPostModel] = useState({
        ...defaultParams,
      });
    const [viewModel, dispatchViewModel, bindingViewModel] = useReducerSmart(defaultViewModel);
    const [credits, setCredits] = useState({
        certificateCredits: 0,
        simulationCredits:0
    });
    const [loading, setLoading] = useState({ isLoading: false, text: "" });
    const [appStoreState, appStoreDispatch] = getAppStore(props);
    const getUrl = "api/Credit/Accessor/GetCreditsAmount";
    const [reloadForm, setReloadForm] = useState("");
    const [data, setData] = useState([]);

    const loadCredits = async ()=>{
        await tryCatchFinally.asyncProcess(async ()=>{
            const getResponse = await getData.processPost(true, getUrl, {});
            if (!getResponse.state) {
                toastPrint.printResponse(getResponse);
                return;
            }
            setCredits({
                certificateCredits: getResponse.certificateCredits,
                simulationCredits: getResponse.simulationCredits
            });
        });
    }

    useEffect(()=>{
        layoutManager.Layout_HideHeadterButton_Footer_HideSideBar(props);
        loadCredits();
    },[]);

    return (
        <Fragment>
            {debug && <p>{JSON.stringify(viewModel)}</p>}
            {loading.isLoading && (<AppLoading active><div>{loading.text}</div></AppLoading>)}
            <div className="app-main__inner">
                <div className="app-page-title">
                    <div className="page-title-wrapper">
                        <div className="page-title-heading">
                            <div className="page-title-icon">
                                <i className="pe-7s-credit icon-gradient bg-tempting-azure">
                                </i>
                            </div>
                            <div>
                                Credit Details
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div data-parent="#HeatingConditionersList3" id="HeatingConditionersListDiv3" className="collapse show">                     
                        <div className="card-body">
                            <div className="page-title-heading">                           
                                <div>
                                    <h5>Simulation Credits: {credits.simulationCredits}</h5>
                                </div>
                            </div>                                               
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div data-parent="#HeatingConditionersList2" id="HeatingConditionersListDiv2" className="collapse show">                     
                        <div className="card-body">
                            <div className="page-title-heading">                           
                                <div>
                                    <h5>Certificate Credits: {credits.certificateCredits}</h5>
                                </div>
                            </div>                                           
                        </div>
                    </div>
                </div>
                <div className="main-card mb-3 card">
                    <div data-parent="#HeatingConditionersList" id="HeatingConditionersListDiv" className="collapse show">                     
                        <div className="card-body">
                            <div className="page-title-heading">                           
                                <div>
                                    <h5>Credit History</h5>
                                </div>
                            </div>
                            <CreditDataTable
                                debug = {debug}
                                data = {data}
                                setData = {setData}
                                extraPostModel = {extraPostModel}
                                url="api/Credit/Accessor"
                                tableReload = {reloadForm}
                                setTableReload = {setReloadForm}
                            ></CreditDataTable>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}
export default  connectAppStore(UserCredits);
