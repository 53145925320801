import React, { Fragment, useEffect, useRef } from 'react';
import objectPropertyValueGetSet from "../Function/ObjectPropertyValueGetSet";
import PageDependencies from './PageDependencies';

const BindedCheckBox = (props)=>{
    const {useState} = PageDependencies();
    const newProps = {};
    const binding = props.binding;
    const bindingObject = binding[0];
    const bindingProperty = binding[1];
    const bindingMethod = binding[2];
    const bindingCallBack = binding[3];
    const bindingValue = objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty) === undefined ? false : objectPropertyValueGetSet.getPropertyValue(bindingObject, bindingProperty);
    const [message, setMessage] = useState("");
    const [value, setValue] = useState(bindingValue);
    const [event, setEvent] = useState();
    const validate = props.validate;
    const checkbox = useRef();

    for (const prop in props) {
        if (!(prop==='binding') && !(prop==='componentRef') && !(prop==='validate')) {
            newProps[prop] = props[prop];
        }
    }

    useEffect(() => {
        checkbox.current.checked = bindingValue;
        if (validate !== undefined) {
            if (validate.setMessages !== undefined) {
                validate.setMessages[1](oldSetMessage => {
                    let newMessages = {};
                    if (oldSetMessage !== undefined) {
                        newMessages = { ...oldSetMessage };
                    }
                    newMessages[bindingProperty] = setMessage;
                    return newMessages;
                });
            }           
        }    
    }, []);

    useEffect(() => {
        if(bindingValue != value){
            setValue(bindingValue, (newCheckValue)=>{
                checkbox.current.checked = bindingValue;
            });
        }
    }, [bindingValue]);

    useEffect(()=>{
        const timer = setTimeout(()=>{
            if(bindingValue != value){ 
                const bindingObjectNew = { };
                bindingObjectNew[bindingProperty] = value;           
                bindingMethod(bindingObjectNew, event, value);
                if (validate !== undefined) {
                    const validateObject = { ...bindingObject };
                    validateObject[bindingProperty] = value;
                    validate.checkValidateResult(validateObject);
                }
                if(bindingCallBack !== undefined){
                    bindingCallBack(value);
                }
            }
        }, 300);
        return () => {
            clearTimeout(timer);
            };
    },[value]);

    const getValidateVisibility = ()=>{
        if(validate === undefined) return false;
        if(validate.validateVisibility === undefined) return false;
        return validate.validateVisibility;
    }

    return <Fragment>
        <input 
            {...newProps} 
            ref={checkbox} 
            type='checkbox'
            className='form-check-input'
            onChange={() => { }}
            onClick={(e)=>{
                setEvent(e, (newEvent)=>{
                    setValue(checkbox.current.checked);
                })
            }}
            />
        {<span id={bindingProperty + "-Validate"} className="field-validation-valid" style={{color:"red"}}>{getValidateVisibility() && message}</span>}
    </Fragment>;
};
export default BindedCheckBox;